import { useGuideStore } from '~/stores/common/guide'
import {
  config as guideConfig,
  options as guideOptions
} from '~/_plugins/guide/configs/app'

export default defineNuxtRouteMiddleware(to => {
  if (process.server) return

  const { $sdk, $guide, $normalizePath } = useNuxtApp()

  $sdk
    .module('auth')
    .then(({ getAccount }) => getAccount())
    .then((account: any) => {
      const guideStore = useGuideStore()

      if (
        account &&
        (!account.profile || account.profile.agree) &&
        !$guide.active &&
        window.innerWidth > 570 &&
        !guideStore.guideIsFinished('welcome') &&
        [$normalizePath('/tasks')].includes(to.path)
      ) {
        guideStore.setLocalStateAsFinished('welcome')
        return $guide.create(guideConfig, guideOptions)
      }
    })
})
